<template>
	<div class="home centered" v-if="loaded">
		<div class="small_title" v-html=" get_word('title_for_hours')"></div>
		<h1 v-html="get_word('hours_title')"></h1>
		<form @submit.prevent="save" style="padding-bottom: 70px">
			<div v-for="day in days" :key="day.day_num" class="day_wrapper">
				<h3>
					יום
					{{ day.name }}
				</h3>
				<activeHoures
					@update:modelValue="hours.hours[day.day_num]"
					:initial_hours="hours.hours[day.day_num]"
					:day_num="day"
				/>
			</div>
			<div class="btn_wrap">
				<input type="submit" class="btn" value="שמירה" />
			</div>
		</form>
	</div>
</template>
<script>
import activeHoures from "@/components/activeHours.vue";
export default {
	components: { activeHoures },
	data() {
		return {
			loaded: false,
			hours: {
				hours: [],
				open_by_sunset: false,
			},
			days: [
				{ day_num: 0, name: "ראשון" },
				{ day_num: 1, name: "שני" },
				{ day_num: 2, name: "שלישי" },
				{ day_num: 3, name: "רביעי" },
				{ day_num: 4, name: "חמישי" },
				{ day_num: 5, name: "שישי" },
				{ day_num: 6, name: "שבת" },
			],
		};
	},
	mounted() {
		let mikve_id = 0;
		if(localStorage.getItem("mikve_id") && localStorage.getItem("mikve_id") > 0)
		{
			mikve_id = localStorage.getItem("mikve_id");
		}
		if(!this.isManager())
		{
			this.$router.push('/');
		}
		this.api({ action: "settings" ,data:{mikve_id}}, (data) => {
			for (let day in data.data.hours.hours) {
				this.hours.hours.push(JSON.parse(data.data.hours.hours[day]));
			}
			this.hours.open_by_sunset = data.data.hours.open_by_sunset == "1" ? true : false;
			this.loaded = true;
		});
	},
	methods: {
		save() {
			

			let mikve_id = 0;
			if(localStorage.getItem("mikve_id") && localStorage.getItem("mikve_id") > 0)
			{
				mikve_id = localStorage.getItem("mikve_id");
			}
			this.api({ action: "save_hours", data: { hours:this.hours ,mikve_id} }, () => {
				this.alert("הפרטים נשמרו בהצלחה");
			});
		},
	},
};
</script>
<style lang="scss" scoped>
.day_wrapper{margin:20px 0;}
h3{font-weight: normal;padding-bottom: 5px;}
.btn_wrap {
	&{display: flex; position: fixed;
    background: #fff;
    bottom: 0px;
    left: 0px;
    right: 0px;
    text-align: center;
    padding: 15px;
    z-index: 999;
    display: flex;
    gap: 15px;
    flex-direction: row-reverse;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);}
	.btn{margin-top: 0px;font-size: 24px;font-weight: 600;}
}
.home {padding-bottom: 80px;}
// Slider
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
