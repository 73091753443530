<template>
	<div id="layout" v-if="loaded">
		<div id="popup" :class="{ box: true, active: isActive }">
			<a id="close" @click="close"><i class="fas fa-times"></i></a>
			<div v-if="!showPayment">
				<h1 v-text="get_word('add_apt_title')"></h1>
				<form @submit.prevent="save()">
					<ul class="frm">
						<div class="panel">
							<li>
								<label class="title" v-text="get_word('order_name')"></label>
								<input type="text" v-model="form.name" required />
							</li>
							<li>
								<label class="title" v-text="get_word('order_time')"></label>
								<vue-timepicker
									:minute-interval="15"
									v-model="form.time"
									format="HH:mm"
	
								></vue-timepicker>
								<!-- close-on-complete -->
							</li>
							<li>
								<label class="title" v-text="get_word('order_phone')"></label>
								<input type="text" v-model="form.phone" required />
							</li>
						</div>
						
					</ul>
					<input type="submit" class="btn" :value="get_word('save')" />
				</form>
			</div>
	
		</div>
	</div>
</template>

<script>
import VueTimepicker from "vue3-timepicker/src/VueTimepicker.vue";

export default {
	props: ["mikve_id", "date","prods"],
	components: { VueTimepicker },
	data() {
		return {
			loaded: false,
			isFree: false,
			paymentId: 0,
			showPayment: false,
			isActive: false,
			form: {
				phone: "",
				name: "",
				type: 1,
				payment: 1,
				time: "",
			},
		};
	},
	mounted() {
		this.loadData();
		setTimeout(() => {
			this.isActive = true;
		}, 150);

		window.addEventListener('message', (event) => {

            if (event.data.startsWith("payment_completed"))
            {
                setTimeout(()=>{
                    this.alert('תשלום בוצע בהצלחה!');
                    this.$router.push('/home');
					this.close();
                    // this.$bus.$emit('reload');
                },1500);
                
            }
        });
		
	},
	computed: {
		time() {
			var d = new Date(); // for now
			return d.getHours() + ":00";
		},
	},
	methods: {
		close() {
			this.isActive = false;
			setTimeout(() => {
				this.$emit("close");
			}, 100);
		},
		save() {
			this.api(
				{
					action: "add_apt",
					data: { data: this.form, date: this.date, mikve_id: this.mikve_id,prods:this.prods },
				},
				(data) => {
					if (data.data) {
						if (+data.data > 1) {
							this.showPayment = true;
							this.paymentId = +data.data;
						} else {
							this.alert("התור נשמר בהצלחה");
							this.close();
						}
					} else {
						this.alert(this.get_word("add_apt_error"));
					}
				}
			);
		},
		add(item){
			if (item.max_qty > item.qty) item.qty++;
        },
        remove(item){
            if (item.qty == 0) 
            {
                item.qty = 0;
            }
            else item.qty--;
        },

		loadData() {
			if(this.prods && this.prods.length > 0)
			{
				this.prods.map((item) => {
					item.qty = 0;
					return item;
				});
			}
			this.loaded = true;
			this.form.time = this.time;
		},
	},
};
</script>

<style lang="scss" scoped>
.checkbox_wrapper{
    &{display: flex;align-items: center;gap:  10px;}
    
    label::after{content: "" !important}
    input{height: 15px !important;width: 15px !important;border-radius: 100%;}
}
#layout{background: rgba(0, 0, 0, 0.2);position: fixed;inset: 0;z-index: 1;}
.title{font-weight: 800;}
iframe{width: 100%;border: none;outline: none;min-height: 500px; }

.btn{display: block;margin:  20px auto;font-weight: 800;font-size: 25px;}
.frm{
        &{max-width: 100%;display: flex;gap: 50px;justify-content: center;}
        @media(max-width:600px) {flex-direction: column;gap:0}
        textarea{resize: none;height: 200px;text-align: right;width: 100%;font-size: 22px;padding: 0 20px;}
        input{text-align: center;width: 100%;font-size: 20px;padding: 0 20px;}
        .btn{width: 100px;display: block;margin: 0 auto;}
    }
    #popup{
        h1{text-align: center;}
        &{ padding: 20px ; ;position: absolute; max-width: 920px; background-color: #fff; margin: 0 auto 50px;left: 50%;;top: 50%;height: 600px;overflow: hidden;border-radius: 10px;max-width: 1000px;width: 100%;animation-duration: 0.5s;
        transform: scale(1.15) translate(-50%,-50%);transition: transform 0.1s cubic-bezier(0.465, 0.183, 0.153, 0.946), opacity 0.1s cubic-bezier(0.465, 0.183, 0.153, 0.946); will-change: transform, opacity;opacity:0;overflow:y-scoll;
        &.active{transform: scale(1) translate(-50%,-50%); opacity: 1;transition: transform 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946), opacity 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946)}
        @media (max-width:600px) {overflow-y: scroll; height: 500px;}
    }
}
	h3{font-size: 18px;}

.prods_wrapper{
	&{display: flex;flex-direction: column;gap: 10px;}
	.item{
		.content{display: flex;    align-items: flex-start;gap: 15px;
			.number_inp{display: flex; align-items: center; font-size: 19px; gap: 6px;border: 1px solid;border-radius: 20px;padding: 0 10px;
			a{display: flex;align-items: center;}
			}
		}
	}
}
</style>