import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Settings from "../views/settings.vue";
import hours from "../views/hours.vue";
import Contact from "../views/contact.vue";
import NotFound from "../views/NotFound.vue";
import inbox from "../views/inbox.vue";
import close_dates from "../views/close_dates.vue";
// import work_ticket from "../views/work_ticket.vue";
import schedule from "../views/schedule.vue";
const routes = [
	{ path: "/settings", name: "settings", component: Settings },
	{ path: "/schedule", name: "schedule", component: schedule },
	{ path: "/", name: "Home", component: Home },
	{ path: "/hours", name: "hours", component: hours },
	{ path: "/contact", name: "contact", component: Contact },
	{ path: "/inbox", name: "inbox", component: inbox },
	{ path: "/close_dates", name: "close_dates", component: close_dates },

	{ path: "/:catchAll(.*)", name: "404", component: NotFound },
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	base: process.env.BASE_URL,
	routes,

	scrollBehavior() {
		return { x: 0, y: 0 };
	},
});

export default router;
