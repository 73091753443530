<template>
	<div class="centered settings" v-if="loaded">
		<form @submit.prevent="save"  style="padding-bottom: 70px">
			<div class="allPanelWrapper">
				<div class="panelWrapper">
					<div class="panel">
						<h1 v-html="get_word('manager_settings')"></h1>
						<div
							v-for="(balanit, i) in balanit_days"
							:key="i"
							class="select_wrapper"
						>
							<label v-text="balanit.name"></label>
							<VueMultiselect
								:disabled="!isManager()"
								v-model="balanit.balan_days"
								:options="managers"
								:multiple="true"
								:searchable="false"
								placeholder="בחרי בלניות"
								label="name"
								track-by="id"
							/>
						</div>
					</div>
				</div>
				<div class="btn_wrapper">
					<input type="submit" class="btn" :value="get_word('save')" />
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
export default {
	components: { VueMultiselect },
	data() {
		return {
			balanit_days: [],
			managers: [],
			loaded: false,
		};
	},
	mounted() {
		let mikve_id = 0;
		if (
			localStorage.getItem("mikve_id") &&
			localStorage.getItem("mikve_id") > 0
		) {
			mikve_id = localStorage.getItem("mikve_id");
		}
		this.api({ action: "settings", data: { mikve_id } }, (data) => {
			this.balanit_days = data.data.balanit_days;
			this.managers = data.data.managers;
			this.loaded = true;
		});
		console.log(this.balanit_days);
	},
	methods: {
		save() {
			let mikve_id = 0;
			if (
				localStorage.getItem("mikve_id") &&
				localStorage.getItem("mikve_id") > 0
			) {
				mikve_id = localStorage.getItem("mikve_id");
			}
			let balan_days = [];
			let str = "";

			for (let i in this.balanit_days) {
				if (
					this.balanit_days[i].balan_days &&
					this.balanit_days[i].balan_days.length > 0
				) {
					for (let z in this.balanit_days[i].balan_days) {
						str += "|" + this.balanit_days[i].balan_days[z].id + "|";
					}
				}
				balan_days.push({ day: +i, str });
				str = "";
			}

			this.api(
				{
					action: "settings_save",
					data: { mikve_id, balan_days },
				},
				() => {
					this.alert("הפרטים נשמרו בהצלחה");
				}
			);
		},
	},
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style lang="scss" scoped>
.settings {
    &{padding-top: 50px;}
    .btn_wrapper{position: fixed;background: #fff;bottom: 0px;left: 0px;right: 0px;text-align: center;padding: 15px;z-index: 999;display: flex; gap: 15px;flex-direction: row-reverse;box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);}
    .select_wrapper{margin: 10px 0 20px;}
}
.panelWrapper{    display: grid; grid-template-rows: 1fr auto; break-inside: avoid; gap: 30px;
}
.panel{background-color: #fff; position: relative; border-width: 0; box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1); border: 1px solid transparent; border-radius: 4px; padding: 0 25px;}
.allPanelWrapper{
	&{padding-bottom: 100px;column-count: 2; column-gap: 30px;}
	@media (max-width:600px) {display: flex;flex-direction: column;}
}
</style>