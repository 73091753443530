<template>
	<div class="active_hours_inp" :id="'active_hours_inp_' + day_num.day_num">
		<input
			type="hidden"
			:name="'day_' + day_num.day_num"
			:value="JSON.stringify(hours)"/>
		<a
			@mouseenter="check_mouse(hour)"
			v-for="(hour, i) in initial_hours"
			:key="i"
			v-text="format_hour(hour.name)"
			:class="{ active: hour.active }"
			@click="handleClick(hour)"
			name="{$name}"
		></a>
	</div>
</template>


<script>
export default {
	props: ['initial_hours',"day_num"],
    emits: ['update:modelValue'],
	data() {
		return {
			hours: [],
			mouseClick: false,
			mouseClickState: true,
		};
	},
	async mounted() {
		document.addEventListener("mousedown", () => {
			this.mouseClick = true;
		});
		document.addEventListener("mouseup", () => {
			this.mouseClick = false;
		});
	

	},
	methods: {
		handleClick(hour) {
			hour.active = !hour.active;
			this.mouseClickState = hour.active;
			this.$emit('update:modelValue', this.modelValue)
		},
		check_mouse(item) {
			if (this.mouseClick) item.active = this.mouseClickState;
		},
		isNull(str) {
			return str == "" || str == null || str == undefined;
		},
		format_hour(str) {
			if (str < 10) return "0" + str;
			return str;
		},
	},
};
</script>

<style>

    .active_hours_inp { display: flex; flex-wrap: wrap; gap:5px;}
    .active_hours_inp a { display: inline-flex; min-width:55px; height: 40px; border:2px solid #FF4855; align-items: center; justify-content: center; color: #FF4855; padding: 2px;
        -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;border-radius: 3px;
    }

    .active_hours_inp a.active { 
        background: #53c54f; color: #fff;border-color: #53c54f;
         }

</style>