<template>
	<div id="app" :class="'rtl ' + (show_side_menu ? 'menu_open' : '')">
		<div id="side_menu" v-if="loaded && !show_login">
			<div class="inner_menu" v-if="!is_mobile()">
				<div id="menu_logo"></div>
				<div id="mikve_select">
					<select v-model="selectedMikve" @change="changeMikve()">
						<option v-for="mikve in user.mikve_list" :key="mikve.id" :value="mikve.id">
							{{ mikve.name }}
						</option>
					</select>
				</div>
				<ul>
				
					<li>
						<div class="link_bg">
							<router-link to="/">תורים מוזמנים</router-link>
						</div>
					</li>
					<li v-if="isManager()">
						<div class="link_bg">
							<router-link to="/hours">שעות פתיחה</router-link>
						</div>
					</li>
					<li v-if="isManager()">
						<div class="link_bg">
							<router-link to="/close_dates">לוח שנה לסגירת ימים</router-link>
						</div>
					</li>
					<li v-if="isManager()">
						<div class="link_bg">
							<router-link to="/settings">הגדרות המשרד</router-link>
						</div>
					</li>
						
					<li>
						<div class="link_bg">
							<a @click="logout()">יציאה</a>
						</div>
					</li>
				</ul>
			</div>
			<div class="mobile" v-else>
				<div class="menu_ham">
					<input
						class="checkbox"
						type="checkbox"
						name=""
						id=""
						v-model="menuActive"
						@click="menuActive = !menuActive"
					/>
					<div class="hamburger-lines">
						<span class="line line1"></span>
						<span class="line line2"></span>
						<span class="line line3"></span>
					</div>
				</div>
				<div id="menu_logo"></div>
			</div>
			<div class="mobile_menu">
				<ul v-if="menuActive">
					<div id="mikve_select">
						<select v-model="selectedMikve" @change="changeMikve()">
							<option
								v-for="mikve in user.mikve_list"
								:key="mikve.id"
								:value="mikve.id"
							>
								{{ mikve.text }}
							</option>
						</select>
					</div>
					<li v-for="menu_item in menu" :key="menu_item.id">
						<router-link
							v-on:click="show_side_menu = false"
							:to="menu_item.link"
							:class="{ active: $route.path == menu_item.link }"
							@click="menuActive ? (menuActive = false) : ''"
							>{{ menu_item.name }}</router-link
						>
					</li>
				</ul>
			</div>
		</div>

		<main id="page" v-if="loaded">
			<LoginPage v-if="show_login" />
			<template v-if="!show_login">
				<router-view
					v-slot="{ Component }"
					@loaded="is_show_loader = false"
					@show_loader="is_show_loader = true"
				>
					<transition name="fade">
						<component :is="Component" :key="$route.path" />
					</transition>
				</router-view>
			</template>
		</main>
	</div>
</template>

<script>
import LoginPage from "./views/login.vue";

export default {
	name: "app",
	components: { LoginPage },
	data() {
		return {
			loaded: false,
			menuActive: false,
			selectedMikve: 0,
			menu_data: [
				{ name: "תורים מוזמנים", link: "/", id: 2 },
				{ name: "שעות פתיחה", link: "/hours", id: 3 },
				{ name: "הגדרות המשרד", link: "/settings", id: 5 },
			],
			menu: [],
			header_class: "",
			show_side_menu: false,
			user: false,
		};
	},
	mounted() {
		window.appVue = this;
		this.$bus.$on("reload", (loadData, openURL) => {
			this.reload(loadData, openURL);
		});
		this.reloadPage();

		
		
		this.$bus.$on("close_menu", this.closeMenu);
		this.$bus.$on("close_menu", this.changeTheme);
	},
	methods: {
		reload(loadData, openURL) {
			this.loaded = false;

			if (this.isNull(openURL)) openURL = "/";

			if (this.$route.path !== "/") this.$router.push(openURL);
			//this.refreshKey++;
			if (loadData !== false) this.reloadPage();
			else this.loaded = true;
		},
		closeMenu() {
			this.show_side_menu = false;
		},
		changeMikve() {
			localStorage.setItem("mikve_id", this.selectedMikve);
			this.$router.go();
		},
		handleMenuToggle() {
			this.show_side_menu = !this.show_side_menu;
		},

		reloadPage(cb = null) {
			let getVars = {};
			let uri = window.location.href.split("?");
			if (uri.length == 2) {
				let vars = uri[1].split("&");

				let tmp = "";
				vars.forEach(function (v) {
					tmp = v.split("=");
					if (tmp.length == 2) getVars[tmp[0]] = tmp[1];
				});
				// do
			}

			if (!this.isNull(getVars.token)) {
				this.set_user_token(getVars.token);
				document.location = "/";
			}

			const platformId = +localStorage.getItem("langId") || 1;
			if (platformId && platformId != 1) document.body.classList.add("ltr");
			else document.body.classList.remove("ltr");
			// categories
			this.api(
				{ action: "general", method: "post", data: { platformId } },
				(data) => {
					// this.menu = data.data.menu;
					this.$store.words = data.data.words;

					this.$store.user = data.data.user;

					this.user = data.data.user;

					// if (!this.isManager()) {
					// 	for (let i in this.menu) {
					// 		if (this.menu[i].id == 3) this.menu.splice(i, 1);
					// 	}
					// }

					if (localStorage.getItem("mikve_id") && +localStorage.getItem("mikve_id") > 0){
						this.selectedMikve = localStorage.getItem("mikve_id");
					} 
					else if (this.user.mikve_list && this.user.mikve_list[0] && this.user.mikve_list[0].id != "") {
						this.selectedMikve = this.user.mikve_list[0].id;
						localStorage.setItem("mikve_id", this.user.mikve_list[0].id);
					}
			
					this.show_login = !this.user;

					this.loaded = true;
					this.$nextTick();

					if (cb && typeof cb === "function") cb();
				}
			);
		},
	},
};
</script>
<style lang="scss">
#side_menu ul {    display: flex;height:100%;justify-content: space-evenly;}
#side_menu .inner_menu {height: 50vh;flex-direction: column;display: flex;justify-content: space-between;    min-height: 350px;}
// #side_menu ul li {
// }
.link_bg {
	&{position: relative;}
	a {position: relative;;z-index: 1;}
	&.active {
		&{z-index: 0;}
		&::before{content: "";position: absolute;background: linear-gradient(270deg, #4C6D87 0%, rgba(76, 109, 135, 0) 100%);right:-20px;
		top:-2px;width:100%;height: 120%;}
	}
	&:hover{
		&{z-index: 0;}
		&::before{content: "";position: absolute;background: linear-gradient(270deg, #4C6D87 0%, rgba(76, 109, 135, 0) 100%);right:-20px;
		top:-3px;width:100%;height: 120%;}
	}
	a.active {
		&{position: relative;}
		&::after{content: ""; position: absolute;right:-44px;top:-5px;border-radius:35%; width:30px;height: 35px;background: #c0e8b1ad;}
	}
}
.menu_ham {display: flex;justify-content: center;align-items: center;z-index: 1;}
.menu_ham .checkbox {position: relative;display: block;height: 32px;width: 32px;z-index: 2;opacity: 0;cursor: pointer;}
.menu_ham .hamburger-lines {display: block;height: 26px;width: 32px;position: absolute;top: 20px;z-index: 1;display: flex;flex-direction: column;justify-content: space-between;}
.menu_ham .hamburger-lines .line {display: block;height: 4px;width: 100%;border-radius: 10px;background: white;}
.menu_ham .hamburger-lines .line1 {transform-origin: 0% 0%;transition: transform 0.4s ease-in-out;}
.menu_ham .hamburger-lines .line2 {transition: transform 0.2s ease-in-out;}
.menu_ham .hamburger-lines .line3 {transform-origin: 0% 100%;transition: transform 0.4s ease-in-out;}
.menu_ham input[type="checkbox"]:checked ~ .hamburger-lines .line1 {transform: rotate(45deg);}
.menu_ham input[type="checkbox"]:checked ~ .hamburger-lines .line2 {transform: scaleY(0);}
.menu_ham input[type="checkbox"]:checked ~ .hamburger-lines .line3 {transform: rotate(-45deg);}
</style>