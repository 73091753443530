<template>
	<div class="home centered">
		<h1>תורים להיום</h1>
		<table class="tbl">
			<thead>
				<tr>
					<th>שעה</th>
					<th>שם</th>
					<th>טלפון</th>
					<th>הגיע</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="item in list" :key="item.id">
					<td v-text="item.apt_time"></td>
					<td v-text="item.user_data.display_name"></td>
					<td v-text="item.user_data.phone"></td>
					<td><input type="checkbox" /></td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
  
  <script>
export default {
	data() {
		return {
			list: [],
		};
	},
	mounted() {
		this.api({ action: "home" }, (data) => {
			this.list = data.data.orders;
		});
	},
};
</script>