<template>
    <router-link to="/managment" class="back_btn"><i class="fas fa-arrow-right"></i></router-link>
    <div class="centered">
        <h1>לוח שנה לסגירת ימים</h1>
          <FullCalendar  :options="calendarOptions" />
    </div>
</template>
<script>

import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'

export default {
	components: { FullCalendar },
	data() {
    
        let mikve_id = 0;
        if(localStorage.getItem("mikve_id") && localStorage.getItem("mikve_id") > 0)
        {
            mikve_id = localStorage.getItem("mikve_id");
        }
        
		return {
			loaded: false,
            calendarOptions: {
                plugins: [ dayGridPlugin, interactionPlugin ],
                initialView: 'dayGridMonth',
                dateClick: this.handleDateClick,
                events: []
            },    
            openPopup: false,
            items: [],
            item_id: 0,
            mikve_id: mikve_id,
            form: {
                name: '',
                mikve_id: mikve_id
            },
		};
	},
	mounted() {
        this.init();
	},
    
	methods: {
        handleDatesSet(event){
            console.log(event);

        },
      
        init(){
            
            let mikve_id = 0;
            if(localStorage.getItem("mikve_id") && localStorage.getItem("mikve_id") > 0)
            {
                mikve_id = localStorage.getItem("mikve_id");
            }
            
            this.api({ action: "close_dates", data: { mikve_id} }, (data) => {
                for(let i in data.data.dates)
                {
                    data.data.dates[i].title = 'יום סגור';
                }
                this.calendarOptions.events = data.data.dates;
            });
        },
       
        handleDateClick(arg) {
            if(this.calendarOptions.events && this.calendarOptions.events.length > 0 )
            {
                let exist = false;
                for(let i in this.calendarOptions.events)
                {
                    if(this.calendarOptions.events[i].date === arg.dateStr) exist = true;
           
                }
                if(exist)
                {
                    if (confirm('האם לפתוח את היום?'))
                        {
                            this.api({ action: "toggle_close_date" ,data:{status:'open',mikve_id :this.mikve_id, date: arg.dateStr}}, () => {
                                this.init();
                            });
                        }
                        return;
                }
                else{
                    if (confirm('האם לסגור את היום?'))
                        {
                            this.api({ action: "toggle_close_date" ,data:{status:'close', mikve_id :this.mikve_id,date: arg.dateStr}}, () => {
                                this.init();
                            });
                        }  
                }
            }
            else{
                if (confirm('האם לסגור את היום?'))
                    {
                        this.api({ action: "toggle_close_date" ,data:{status:'close', mikve_id :this.mikve_id,date: arg.dateStr}}, () => {
                            this.init();
                        });
                    }
            }
            
        },
        add(){
            this.form.name = '';
            this.openPopup = true;
        },
        remove(id){
            if (confirm('האם למחוק את החדר?'))
            {
                this.api({ action: "remove_pit" ,data:{ id: id}}, () => {
                    this.init();
                });
            }
        },
		save() {
			this.api({ action: "add_pit", data: this.form }, () => {
				this.toast("הפרטים נשמרו בהצלחה");
                this.openPopup = false;
                this.init();
			});
		},
	},
};
</script>
<style lang="scss" scoped>
.time_wrapper{margin: 10px 0;}
.radio_wrapper{display: flex;flex-direction: column;}
.day_wrapper{margin:20px 0;}
h3{font-weight: normal;padding-bottom: 5px;}
.btn_wrap {
	&{display: flex; position: fixed; background: #fff; bottom: 0px;left: 0px; right: 0px; text-align: center; padding: 15px; z-index: 999; display: flex; gap: 15px; flex-direction: row-reverse; box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);}
	.btn{margin-top: 0px;font-size: 24px;font-weight: 600;}
}

#room_list { display: grid;   grid-template-columns: repeat(3, 1fr); gap:25px;

  @media (max-width:600px) { grid-template-columns: repeat(1, 1fr);}


    .room { font-size: 16px;
    &.close{background: #e4e4e4;}
        h3 { font-weight: bold; font-size: 26px;}
        label { font-weight: bold; margin-left: 8px;}
     }

}

.panel_day {  padding: 15px 25px; box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, #00418b 0px 0px 0px 0px; width: 90%; margin: 10px auto; border-radius: 10px;
    .day_title{    text-align: center; font-weight: 600; text-decoration: underline;}
}
.panel { padding: 15px 25px;}

.btn.red { background: #d32727;}

.checkbox { 
    label { display: flex; gap:10px; align-items: center;}
}

.time_day { display: flex; gap:6px; margin-bottom: 10px;}
</style>