<template>
<div id="layout" v-if="loaded">
    <div id="popup" :class="{ box: true, active: isActive}">
        <a id="close" @click="close"><i class="fas fa-times"></i></a>
        <h1 v-text="get_word('how_payed')"></h1>
        <ul>
            <div class="panel">
                <li>
                <label class="title" v-text="get_word('payment_type')"></label>
                <div class="radio_wrapper">
                    <div>
                        <input type="radio" id="reg_type" v-model="type" value="1"/>
                        <label for="reg_type" v-text="get_word('cash')"></label>
                    </div>
                    <div>
                        <input type="radio" id="org_type" v-model="type" value="2"/>
                        <label for="org_type" v-text="get_word('credit')"></label>
                    </div>
                    <div>
                        <input type="radio" id="exceptional_type" v-model="type" value="3"/>
                        <label for="exceptional_type" v-text="get_word('other')"></label>
                    </div>
                </div>  
                </li>
            </div>
        </ul>
        <button @click="save()" class="btn" v-text="get_word('save')"/>
    </div>
  </div>
</template>

<script>

export default {
    props:['order_id'],
    components:{},
    data() {
        return {
            loaded: false,
            showPayment: true,
            isActive: false,
            type:1,
        }
    },
    mounted(){
        this.loadData();
        setTimeout(()=>{
             this.isActive = true;
        },150);

    },
 
    methods:{    
        save(){
            this.api({ action: 'order_payed', data: {order_id:this.order_id,type:this.type}}, () => {
                this.alert("הפרטים נשמרו בהצלחה");
                this.close();                       
            });
        },
        close(){
             this.isActive = false;
            setTimeout(()=>{
                this.$emit('close');
            },100);
        },
      
    

        loadData(){  
           this.loaded = true;
        }
    }
}
</script>

<style lang="scss" scoped>
#layout{background: rgba(0, 0, 0, 0.2);position: fixed;inset: 0;z-index: 1;}
.title{font-weight: 800;}
iframe{width: 100%;border: none;outline: none;min-height: 500px;  }

.btn{display: block;margin:  20px auto;font-weight: 800;font-size: 25px;}
.frm{
        &{max-width: 100%;display: flex;gap: 50px;justify-content: center;}
        textarea{resize: none;height: 200px;text-align: right;width: 100%;font-size: 22px;padding: 0 20px;}
        input{text-align: center;width: 100%;font-size: 20px;padding: 0 20px;}
        .btn{width: 100px;display: block;margin: 0 auto;}
    }
    #popup{
            h1{text-align: center;}
        &{ padding: 20px ; ;position: absolute; max-width: 920px; background-color: #fff; margin: 0 auto 50px;left: 50%;top: 50%;height: 300px;overflow: hidden;border-radius: 10px;max-width: 1000px;width: 100%;animation-duration: 0.5s;
        transform: scale(1.15) translate(-50%,-50%);transition: transform 0.1s cubic-bezier(0.465, 0.183, 0.153, 0.946), opacity 0.1s cubic-bezier(0.465, 0.183, 0.153, 0.946); will-change: transform, opacity;opacity:0;overflow:y-scoll;
        &.active{transform: scale(1) translate(-50%,-50%); opacity: 1;transition: transform 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946), opacity 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946)}
        @media (max-width:600px) {overflow-y: scroll; height: 500px;}
    }
}
</style>