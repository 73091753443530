<template>
  <section>
    <div class="inner_form_wrap" style="align-items: stretch;">
      <a style="margin-bottom: 10px;" @click="$emit('return')" v-html="get_word('login_with_mail')"></a>
      <sendOtp v-if="step === 1" :phoneNumber="phoneNumber" @success="successOtpSend" />
      <validateOtp v-if="step === 2" :phoneNumber="phoneNumber" />
    </div>
  </section>
</template>
<script>
import SendOtp from '@/components/OTP/SendOtp.vue'
import ValidateOtp from '@/components/OTP/ValidateOtp.vue'
export default {
  components: { SendOtp, ValidateOtp },
    data() {
        return {
          step: 1,
          phoneNumber: ''
        }
    },
    methods: {
      successOtpSend(phone){
        this.phoneNumber = phone;
        this.step++;
      }
    }
}
</script>
