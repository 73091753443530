<template>
    <div >
        <h2 class="title" v-html="get_word('my_number_is')"></h2>
        <ul >
            <li>
                <input   class="styled_input" type="text" id="phone" v-model="identification" autocomplete="off" required/>
            </li>
            <button type="submit" @click="sendOtp" class="btn primary full_width" v-text="get_word('otp_sms')"></button>
        </ul>
    </div>
</template>
<script>
export default {
  props: {
    phoneNumber: {
      required: true,
      type: String,
    },
    
  },
    data() {
        return {
            identification:'',
        }
    },
    mounted(){
        if(this.phoneNumber != '') this.identification = this.phoneNumber;
    },
    methods:{
        sendOtp(ev,type='SMS')
		{
            if (!this.identification) return this.alert('יש להזין מספר טלפון', '', 'אישור')
            ev.preventDefault();
            
            let identification = this.identification;
            
			this.api({ action: 'signup' , data: { identification:identification,type }}, (data) => {
                if(data.data == "mail_not_valid")
                {
                    this.alert(this.get_word('_EMAIL_IS_INVALID_'));
                }
                else if(!data.data)
                {
                    this.alert(this.get_word('wrong_code'));
                }
                else{
                     this.$emit("success" , identification);
                }
            });
		},
    }
}
</script>
<style lang="scss" scoped>
.title {font-weight:normal}
</style>