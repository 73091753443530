<template>
	<div id="login_page_wrap">
		<div class="logo"></div>
		<div id="login_page">
			<div id="form_wrapper" md-theme="default-dark">
				<div v-if="is_loading">
					<div class="lds-ring">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</div>
				<div v-if="!is_loading && !isOtp">
					<div>
						<div class="inner_form_wrap">
							<div class="icon"></div>
							<form @submit.prevent="login()" v-if="show_page == 'login'">
								<div class="title_sign" v-html="get_word('signin_text')"></div>
								<ul>
									<li>
										<div class="mail"></div>
										<input
											class="input_field"
											type="text"
											v-model="username"
											:placeholder="get_word('username_placeholder') + ' *'"
											name="username"
											required=""
										/>
									</li>
									<li>
										<div class="pass_lock"></div>
										<input
											class="input_field"
											:type="(showPass ? 'text' : 'password')"
											name="password"
											v-model="password"
											:placeholder="get_word('_PASSWORD_') + ' *'"
											required=""
										/>
										<div class="control">
											<div class="button" @click="showPass = !showPass">
												<i
													:class="[
														!showPass
															? 'fa-regular fa-eye'
															: 'fa-regular fa-eye-slash',
													]"
												></i>
											</div>
										</div>
									</li>
								</ul>
								<!-- <md-checkbox v-model="remember_me" value="1" class="md-primary">Remember me</md-checkbox> -->

								<div style="margin-bottom: 15px; text-align: center">
									<input
										type="submit"
										:value="get_word('get_in')"
										class="btn primary full_width"
									/>
								</div>
								<div class="bottom_links">
									<a
										@click="show_page = 'forgot'"
										v-html="get_word('forgot_password')"
									></a>
									<a @click="isOtp = true" v-html="get_word('login_otp')"></a>
								</div>
							</form>
						</div>
						<form @submit.prevent="forgot()" v-if="show_page == 'forgot'">
							<div class="title_sign" v-html="get_word('reset_password')"></div>
							<ul>
								<li>
									<div class="mail"></div>
									<input
										class="input_field"
										type="email"
										v-model="forgotForm.email"
										name="email"
										:placeholder="get_word('username_placeholder')"
										required
									/>
								</li>
								<div class="note" v-html="get_word('forgot_pass_note')"></div>
							</ul>

							<div style="margin-bottom: 15px; text-align: center">
								<input
									type="submit"
									:value="get_word('send_mail')"
									class="btn primary full_width"
								/>
							</div>
							<div class="bottom_links">
								<a
									@click="show_page = 'login'"
									v-html="get_word('back_to_login')"
								></a>
							</div>
						</form>
					</div>
				</div>
				<loginOtp v-if="!is_loading && isOtp" @return="isOtp = false" />
			</div>
		</div>
	</div>
</template>

<script>
import loginOtp from "@/views/loginOtp";

export default {
	components: { loginOtp },
	data() {
		return {
			forgotUrl: window.site_url + "signup/forgotPass",
			FB: {},
			model: {},
			scope: {},
			isOtp: false,
			showPass: false,

			token: "",
			show_step_b: false,
			is_loading: false,
			username: "",
			password: "",
			remember_me: false,

			show_page: "login",

			accept_terms: false,
			accept_mailings: false,

			signupForm: {
				display_name: "",
				username: "",
				password: "",
			},

			forgotForm: {
				email: "",
			},
		};
	},
	mounted() {
		document.querySelector("input").focus();
	},
	computed: {
		pageTitle() {
			switch (this.show_page) {
				case "login": {
					return this.get_word("login");
				}
				case "forgot": {
					return this.get_word("forgot_password_title");
				}
				default: {
					return this.get_word("signup");
				}
			}
		},
	},
	methods: {
		stripTags(html) {
			const tmp = document.createElement("div");
			tmp.innerHTML = html;
			return tmp.textContent || tmp.innerText || "";
		},
		login() {
			this.is_loading = true;

			const formData = {
				password: this.password,
				username: this.username,
			};

			this.api({ action: "login", data: formData }, (data) => {
				if (this.isNull(data) || this.isNull(data.data)) {
					this.is_loading = false;
					this.alert(this.get_word("wrong_details"));
					//alert('Wrong details. Please try again.');
				} else {
					this.set_user_token(data.data);
					if (this.isApp) this.afterLogin();
					// document.location = document.location.href;
					this.$bus.$emit("reload");
				}
			});
		},

		forgot() {
			this.is_loading = true;

			this.api({ action: "forgot_password", data: this.forgotForm }, (data) => {
				this.is_loading = false;

				if (data.data === true) {
					this.alert(this.get_word("forgot_pass_step1_finish"));
					this.show_page = "login";
				} else {
					this.alert(this.get_word("user_not_found"));
				}
			});
		},

		afterLogin() {
			var self = this;

			window.cordova.plugins.firebase.messaging
				.requestPermission({ forceShow: true })
				.then(function () {
					window.cordova.plugins.firebase.messaging.subscribe("main");

					window.cordova.plugins.firebase.messaging
						.getToken()
						.then(function (token) {
							self.api(
								{ action: "user/set_push_token", data: { token: token } },
								function () {}
							);
						});
				});
		},
		login_fb() {
			if (this.isApp) {
				window.login_fb();
			} else {
				document.location = window.site_url + "/cfacebook/login";
			}
		},
		login_g() {
			if (this.isApp) {
				window.plugins.googleplus.login(
					{
						scopes: "profile email", // optional, space-separated list of scopes, If not included or empty, defaults to `profile` and `email`.
						webClientId:
							"216641503463-ea7fclo30kea3ep3vqektgg2g17d8ugu.apps.googleusercontent.com", // optional clientId of your Web application from Credentials settings of your project - On Android, this MUST be included to get an idToken. On iOS, it is not required.
						offline: true, // optional, but requires the webClientId - if set to true the plugin will also return a serverAuthCode, which can be used to grant offline access to a non-Google server
					},
					function (obj) {
						alert(JSON.stringify(obj)); // do something useful instead of alerting
					},
					function (msg) {
						alert("error: " + msg);
					}
				);
			} else {
				document.location = window.site_url + "cgoogle/login";
			}
		},
	},
};
</script>

<style lang="scss">
$bluish: #354E68;
#login_page_wrap{
	&{overflow: hidden; display: flex;background: $bluish; background-size: 100% 100%;height: 100%;}
	ul { 
  li { display: block; margin-top: 15px;
    label { display: block; font-size: 17px; color: rgba(0, 0, 0, 0.9);  padding-right: 15px;}
    input:not([type=checkbox]) { width:100%; height: 50px; background: #eee; border:none; border-radius: 12px; padding: 0 25px; font-size: 20px; }
    span { display: block; text-align: left; font-size: 15px; color: rgba(0, 0, 0, 0.5); padding-left: 15px;}
  }
}

.md-field label { left: auto; right: 0;}
.md-field .md-count, .md-field .md-error, .md-field .md-helper-text { left: 0;}


	
	
	*{color: var(--clr_main);}
	.side_text{
		&{position: absolute; top:45%; left:15%;color:#E5FFE8 !important;font-size:48px;font-weight: bold;line-height: 1.1;max-width: 425px;text-align: center;}
		@media (max-width:600px) {display: none;}
	}
	.logo {
		&{position: absolute;width: 15%;height: 70px; left:3%; top:5%; background: url("../assets/logo.png"); background-size: contain; background-repeat: no-repeat;}
		@media (max-width:600px) {width:40%;top:2%;left:30%}
	}
	.border_title { color: #A2A2A2; font: 23px sans-serif;  margin-top: 30px; text-align: center; position: relative; z-index: 1; margin: 15px 0px;    
		span { background: var(--clr_main2); padding: 0 15px; color: var(--clr_main);}
		&::before { border-top: 2px solid #dfdfdf; content: ""; margin: 0 auto; position: absolute; top: 50%; left: 0; right: 0; bottom: 0; width: 95%; z-index: -1; }
	}
	input[type=text], input[type=password], input[type=email]{outline: none;color: #121212;}
	#login_page {
		&{max-width:80%; margin:0 auto;display: flex; justify-content: center; align-items: center;}
    @media (max-width:600px) { align-items: flex-start;}
		#form_wrapper{
			&{padding: 30px;position: absolute;width: 25%;min-width: 350px; margin: 0 auto;background: #FFFFFF;
			box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.15);border-radius: 32px;}
			@media (max-width:1500px) {width: 40%;} 
			@media (max-width:600px) {margin-bottom: 35px;margin-top: 25%;padding: 15px;position: relative;z-index: 1;width: 25%;left: initial;background: #FFFFFF;box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.15);border-radius: 32px;}
			.inner_form_wrap {
        &{display:flex; flex-direction: column;justify-content: center;align-items: center;}
        form {width: 100%;}
			}
      .title_sign {font-size:40px; color:$bluish; text-align: center;line-height: 1.2;font-weight: bold;padding: 5px 0 ;}
			.note {color:grey;padding-top: -5px; padding-right: 15px; font-size: 16px;}
			li { position: relative;display: flex; align-items: center;}
			.input_field {
				&{padding-right: 50px;}
				&::placeholder{ color:$bluish;size: 18px;}
			}
			.user, .mail, .pass_lock {position: absolute;width: 30px;height: 30px;right:15px}
			.user {background: url("../assets/login/user_icon.png") no-repeat right;}
			.mail {background: url("../assets/login/mail_icon.png") no-repeat right;}
			.pass_lock {background: url("../assets/login/pass_icon.png") no-repeat right;}
			.control{
				&{position: absolute;left:10px;
				-webkit-user-select: none;-ms-user-select: none;user-select: none;}
				.button {cursor: pointer;}
				span {font-size:20px;padding-left: 0;}
			}
		}
	} 

	input:-webkit-autofill,
	input:-webkit-autofill:hover, 
	input:-webkit-autofill:focus, 
	input:-webkit-autofill:active  {-webkit-box-shadow: 0 0 0 30px #eee inset !important;}

	.fb_btn, .g_btn { text-align: center; width: 100%; height: 48px; line-height: 48px; border-radius: 6px; display: inline-block; color: #fff !important;   font-size: 16px; padding: 0 15px; padding-left: 41px; margin-bottom: 15px;  }
	.fb_btn { background: #30487b /*url(../images/f_ico.png)*/ 10px center no-repeat; }
	.g_btn {  background: #f14338 /*url(../images/f_ico.png)*/ 10px center no-repeat; }


	.bottom_links { text-align:center; display:flex; justify-content: space-between; 
		a { text-decoration: underline;}
	}

.terms_mails {
  &{display: flex; flex-direction: column;}
  label {padding-right: 10px;color:grey !important}
}
.btn {background: $bluish;width:100%;height:100%;border-radius: 12px;margin-top: 15px;font-size: 20px;font-weight: bold;padding: 5px 0 ;}

}
</style>