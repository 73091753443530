<template>
	<div>
		<h2 class="title" v-html="get_word('my_otp_is')"></h2>
		<ul>
			<li>
				<input class="styled_input" type="text" id="phone" v-model="otp" autocomplete="one-time-code" required/>
			</li>
			<button type="submit" @click="validateOtp" class="btn primary full_width" v-text="get_word('proceed')"></button>
		</ul>
	</div>
</template>

<script>
// import VueCountdown from '@chenfengyuan/vue-countdown';

export default {
	// components: { VueCountdown },
	props: {
		phoneNumber: {
			required: true,
			type: String,
		},
	},
	data() {
		return {
			allowCodeSend: true,
			otp: "",
		};
	},
	methods: {
		startCountdown() {
			this.$emit("sendOtp");
			this.allowCodeSend = true;
		},
		onCountdownEnd() {
			this.allowCodeSend = false;
		},
		validateOtp() {
			var phone = "";
			this.allow_code_send = false;
			phone = this.phoneNumber;

			this.api({ action: "validate_otp", data: { identification: phone, otp: this.otp },}, (data) => {
          if (data.data === false) {
            this.alert(this.get_word("wrong_code"));
          } else {
            this.set_user_token(data.data);
            //if (this.isApp) this.afterLogin();
            this.$bus.$emit("reload");
          }
			});
		},
	},
};
</script>
<style lang="scss" scoped>
.title {font-weight:normal}
</style>