<template>
	<div id="inbox_page">
		<div class="centered-big">
			<h2
				style="font-size: 30px; text-align: center; padding-top: 150px"
				v-if="empty && activeItem == false"
			>
				אין כרגע שיחות פעילות..
			</h2>
			<div class="md-layout" v-if="!empty || activeItem != false">
				<div
					v-show="!(activeItem && is_mobile())"
					:class="
						' threads_wrapper ' +
						(!side_menu ? 'side_menu_opened_emit' : 'side_menu_closed_emit')
					"
				>
					<transition name="slide">
						<div
							:class="
								'therd ' + (!side_menu ? 'side_menu_opend' : 'side_menu_closed')
							"
						>
							<div id="thread_filter">
								<a>כל ההודעות</a>
							</div>
							<div id="threads">
								<ul class="list">
									<li
										v-for="item in listComp"
										:key="item.id"
										@click="open_thread(item)"
										:class="{
											active: item.is_active,
											to_read: !item.is_balan,
											disabled: item.user_data == false,
										}"
									>
										<span
											class="num"
											v-if="item.msg_to_read > 0"
											v-text="item.msg_to_read"
										></span>
										<div class="thread_msg_wrapper">
											<!-- <UserIcon size="sml" :user_data="item.user_data" :clickable="false" /> -->
											<div style="width: 100%">
												<div
													style="
														display: flex;
														justify-content: space-between;
														margin-bottom: 5px;
													"
												>
													<span
														class="name"
														v-html="
															item.user_data == false
																? 'הכרטיס אינו פעיל'
																: user_status_line_inbox(item.user_data)
														"
													></span>
													<div class="date" v-text="item.date_parsed"></div>
												</div>

												<div class="msg" v-text="item.msg"></div>
											</div>
										</div>
									</li>
									<li v-show="on_loading">
										<div class="search_loading">
											<span
												v-text="
													'טוען שיחות' + (list.length > 0 ? ' נוספות' : '')
												"
											></span>
											<div class="loading_stage">
												<div class="dot-falling"></div>
											</div>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</transition>
				</div>

				<div
					class="chat_area_wrapper"
					id="chat_area_wrapper"
					v-if="activeItem || !is_mobile()"
				>
					<template v-if="activeItem">
						<div
							class="chat_user_details_wrapper"
							id="chat_user_details_wrapper"
						>
							<div class="details">
								<a v-show="is_mobile()" @click="close()" style="color: #fff"
									><i class="fas fa-long-arrow-alt-right"></i
								></a>
								<!-- <UserIcon
									size="sml"
									:user_data="activeItem.user_data"
									:clickable="activeItem.user_data.active !== false"
								/> -->
								<div v-if="activeItem.user_data.active !== false">
									<span
										class="name"
										v-text="activeItem.user_data.display_name"
									></span>
								</div>
								<div v-else>
									<span
										class="name"
										v-text="activeItem.user_data.first_name"
									></span>
								</div>
							</div>
						</div>
						<div class="chat_wrapper" :data-id="activeItem.user_data.id">
							<Chat
								v-if="validate_id(activeItem.user_data.id)"
								:key="activeItem.user_data.gettoken"
								:user_id="activeItem.user_data.gettoken"
								:user_data_prop="activeItem.user_data"
								@message_sent="update_thread_date"
								ref="chatbox"
							/>
						</div>
					</template>
					<template v-else
						><div
							style="
								height: 100%;
								display: flex;
								align-items: center;
								justify-content: center;
								flex-direction: column;
							"
						>
							<div v-html="get_word('mailbox_no_open_chats')"></div></div
					></template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
//import Threads from '@/components/inbox/threads.vue'
//import ChatUserDetails from '@/components/inbox/user_details.vue'
import Chat from "@/components/chat.vue";

export default {
	props: ["menu"],
	components: { /*Threads,*/ Chat /*, ChatUserDetails */ },
	data() {
		return {
			list: [],
			page: 1,
			on_loading: false,
			stopLoading: false,
			empty: false,
			side_menu: false,
			activeItem: false,
			filter: "all",
		};
	},

	computed: {
		listComp() {
			let list = this.list;
			list = list.sort(function (a, b) {
				// Turn your strings into dates, and then subtract them
				// to get a value that is either negative, positive, or zero.
				return new Date(b.date_updated) - new Date(a.date_updated);
			});
			/*
      return this.list.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.date_updated) - new Date(a.date_updated);
      });
*/
			return list;
		},
	},

	methods: {
		close() {
			this.activeItem.is_active = false;
			this.activeItem = false;
		},

		onScrollEvent() {
			let scroll_container = document.getElementById("threads");
			let scroll_margin = 400;

			if (
				!this.stopLoading &&
				!this.on_loading &&
				scroll_container.scrollTop + scroll_margin >=
					scroll_container.scrollHeight - scroll_container.offsetHeight
			) {
				this.page++;
				this.get_threads();
			}
		},

		init_threads() {
			this.page = 1;
			this.list = [];
			this.get_threads();
		},

		get_threads() {
			this.on_loading = true;
			this.$emit("loaded");
			let mikve_id = 0;
			if (
				localStorage.getItem("mikve_id") &&
				localStorage.getItem("mikve_id") > 0
			) {
				mikve_id = localStorage.getItem("mikve_id");
			}
			this.api(
				{
					action: "chat/get_mikve_threads",
					data: { page: this.page, filter: this.filter, mikve_id },
				},
				(data) => {
					//this.$emit('loaded');

					if (data.data.list.length < 20) this.stopLoading = true; // Limit is 25 but for some accounts that are terminated i take security margin

					for (let i in data.data.list) {
						data.data.list[i].is_active = false;
					}

					if (this.page == 1) this.list = data.data.list;
					else this.list.push(...data.data.list);

					let open_id = false;
					if (!this.isNull(this.$route.params.id))
						open_id = this.$route.params.id;
					else if (!this.isNull(window.location.hash))
						open_id = window.location.hash.replace("#", "");

					if (this.page == 1 && this.validate_id(open_id)) {
						let found = false;

						for (let i in this.list) {
							if (open_id == this.list[i].user_data.gettoken) {
								found = true;
								this.open_thread(this.list[i]);
								break;
							}
						}

						if (!found && this.validate_id(open_id)) {
							this.api(
								{ action: "search/get", data: { id: open_id } },
								(data) => {
									let new_user = {
										chat_code:
											Math.min(this.get_me().id, open_id) +
											"_" +
											Math.max(this.get_me().id, open_id),
										date_parsed: "עכשיו",
										is_hide: "0",
										msg: "",
										msg_to_read: 0,
										uid: open_id,
										user_data: data.data,
										is_active: false,
									};

									this.list.unshift(new_user);

									this.open_thread(new_user);
								}
							);
						}
					}

					if (
						this.page == 1 &&
						this.isNull(this.list) &&
						!this.validate_id(open_id)
					) {
						this.$emit("empty");
					}
					this.on_loading = false;
				},
				() => {
					this.on_loading = false;
					this.alert("הטעינה נכשלה. אנא נסה שנית");
				}
			);
		},

		open_thread(item) {
			if (item.user_data == false) return;

			let user = item.user_data;

			if (this.activeItem) this.activeItem.is_active = false;
			item.is_active = true;

			item.msg_to_read = 0;

			this.activeItem = item;
			this.side_menu = false;

			//history.replaceState({}, null, '/inbox/' + id)

			window.location.hash = user.gettoken;
		},

		parse_user_channel(data) {
			for (let i in this.list) {
				if (data.uid_from == this.list[i].user_data.id) {
					this.list[i].msg_to_read++;
					break;
				}
			}

			if (this.activeItem && this.activeItem.user_data.id == data.uid_from) {
				this.$refs.chatbox.pushMessage({
					author: data.uid_from == this.get_me().id ? "me" : data.uid_from,
					type: "text",
					data: { text: data.msg },
				});
			}
		},

		hideThread(id) {
			this.$confirm("אתה את/ה בטוח/ה שברצונך להסתיר את השיחה?").then(() => {
				this.api(
					{ action: "chat/remove_thread", data: { id: id } },
					(/*data*/) => {
						this.alert("השיחה לא תוצג יותר");
					}
				);

				for (let i in this.list) {
					if (this.list[i].uid == id) {
						this.list.splice(i, 1);
						break;
					}
				}

				this.close();

				//do something...
			});
		},

		onGoBack() {
			let id = window.location.hash.replace("#", "");
			if (id == "") {
				this.close();
			} else if (
				id != "" &&
				(!this.activeItem || this.activeItem.user_data.gettoken != id)
			) {
				for (let i in this.list) {
					if (this.list[i].user_data.gettoken == id) {
						this.open_thread(this.list[i]);

						break;
					}
				}
			}
		},

		update_thread_date(uid, msg) {
			let yourDate = new Date();

			for (let i in this.list) {
				if (uid == this.list[i].user_data.id) {
					this.list[i].msg = msg;
					this.list[i].date_updated = yourDate.toISOString();
					break;
				}
			}

			//this.activeItem.date_updated = yourDate.toISOString()
		},
	},
	mounted() {
		this.$emit("show_loader");

		this.get_threads();

		// this.$eventBus.$on('new-msg', this.parse_user_channel);

		window.addEventListener("popstate", this.onGoBack);

		setTimeout(() => {
			let elem;
			if (this.is_mobile()) elem = window;
			else elem = document.getElementById("threads");
			elem.addEventListener("scroll", this.onScrollEvent);
		}, 300);
	},
	beforeUnmount() {
		// this.$eventBus.$off('new-msg');

		window.removeEventListener("popstate", this.onGoBack);

		//document.getElementById('threads').removeEventListener('scroll', this.onScrollEvent);
	},
};
</script>

<style lang="scss">
#inbox_page {
  
  .md-layout {
    &{display: flex; gap: 50px; /*height:calc(100vh - 130px - 30px);*/ }
    .threads_wrapper{flex:1;z-index: 9; 
      @media (min-width: 600px) { width:30%;}
    }
    .chat_area_wrapper{
		&{flex:3;max-height: 95vh /*calc(100vh - 130px - 30px);overflow: auto;*/}
		@media (max-width: 600px) { max-height: 85vh; }
	}
    .chat_user_details_wrapper{ display: flex; width: 100%; background:#354E68; min-height: 90px; gap: 10px; padding:0 10px; align-items: center; border-radius: 20px; color: #fff;
      .details { display: flex; gap: 10px; align-items: center; 
        .user_icon {
           @media (min-width: 600px) { width: 70px;height: 70px; }
           @media (max-width: 600px) { width: 50px;height: 50px; }
        }
        .name {  font-size: 22px !important; font-weight: bold;}
        h5 { font-weight: normal; font-size: 18px; white-space: nowrap; overflow: hidden; }
      }
      .options_line { text-align:left; flex:1; }
    }
  }
  .sc-user-input {
	@media (max-width: 600px) {width: 90%;}
  }
  .threads_wrapper { color:#333; }
  #chat_area_wrapper { background:#f1f1f3;  border-radius: 20px;
    .sc-chat-window { /*height: calc(100vh - 130px - 90px - 30px) !important;*/ max-height: 100% !important; position: static; background:none;
        @media (max-width: 600px) {  height: calc(100vh - 70px ) !important; }
    }
    .sc-message-list { background: none !important; } 
  }
  
  .sc-message { width: auto  !important;}
  @media (max-width: 600px) {
    .side_menu_closed_emit{position: fixed;width: 100%;height: 100%;z-index: 9;}
    .chat_user_details_wrapper{display: none; min-height: 70px !important; }
 
    .chat_area_wrapper{overflow: hidden !important; }
      /*{max-height: 100vh;overflow: hidden;}*/
      .centered-big{max-width: 100% !important;}
      #chat_user_details_wrapper{position: relative;
    z-index: 1;min-width: 100%;max-width: 100%; }

      #threads .list li .msg{ font-size: 15px; }
      .user_icon.size_sml{width: 50px;height: 50px; border-radius: 100%;}
      .md-layout{ max-height: initial; overflow: hidden; /*position: fixed; */width: 100%; display: block; height: auto; }
      .md-layout-item.md-size-50{min-width: 70%;}
      #threads{max-height: initial;overflow-y: initial; }

    .chat_user_details_wrapper { flex-wrap: wrap; padding: 15px !important;
      .user_icon.size_sml { width: 40px; height: 40px;}
      .options_line { text-align:center !important; }
    }

    .dt_btn_icon,.dt_btn { font-size: 16px; padding: 0 8px; min-width: 35px; justify-content: center;}

    .sc-message-list { height: calc(100% - 114px) !important;}
  }
}

/* THREADS */
#nav-icon1{height: 30px;margin: 3px ;width: 35px;}
 #nav-icon1 span{color: #fff !important;}

  .side_menu_opend #menu_hamburger #nav-icon1 span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }
  
  .side_menu_opend {
    .fa-chevron-left{display: none;}
  }
  .side_menu_closed{
    &{transition: .3s all ease-in-out}
    .user_icon.size_sml{width: 70px; height: 70px;}
    #threads .list li{padding: 12px;}
    .fa-chevron-right{display: none;}
  }
.side_menu{
      &{transition: .3s all ease-in-out}
  #menu_hamburger{position: absolute;
    left: -29px;
    right: auto;
    background: #4c4c4c;
    width: 30px;
    line-height: 32px;
    height: 30px;text-align: center;}
 
}

.therd{position: relative;}
#threads {
    &{max-height: 90vh /*calc(100vh - 130px - 30px - 42px)*/;overflow: auto;
        @media only screen and (max-width: 600px) { max-height: calc(100vh - 60px); }
    }
    .list { 
        li { 
			&{margin:20px 0;display: block; cursor: pointer; overflow: hidden; padding: 15px; border-radius: 20px; position: relative; background: #f1f1f3;}
            &.active { background: rgb(78 140 255 / 25%); }
            &.to_read{ background: rgb(255 0 0 / 25%); }
            &.disabled { opacity: 0.7; cursor: default;}
            &.to_read { 
                .num { position: absolute; position: absolute; background: red; color: #fff; border-radius: 100%; text-align:center; line-height:20px; font-size: 15px;; width: 20px; height: 20px; right: 10px; top: 10px; z-index: 2; }
             }

            .thread_msg_wrapper { display: flex; gap:10px;}

            // .user_icon  { }
            .name { display: block; font-weight: bold; color: #354E68;}
            .msg { display: block; font-size: 15px; color: #999; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 90%; max-width: 70vw;}
            .date { display: block; font-size: 15px; color: #999; }
        }
    }
}

#thread_filter { display: flex; border:1px solid var(--color);  border-radius: 20px; overflow: hidden;
  a { line-height: 40px; text-align: center; flex:1; color: var(--color); font-size: 16px;
    &.active { background: var(--color); color:#fff;}
  }
}
</style>