<template>
<div id="layout" v-if="loaded">
    <div id="popup" :class="{ box: true, active: isActive}">
        <a id="close" @click="close"><i class="fas fa-times"></i></a>
        <template v-if="paymentId > 0">
        <h1>תשלום</h1>
        <iframe :src="'https://api.mikve.online/payments/do_redirect/'+ paymentId"></iframe>
    </template>
    </div>
  </div>
</template>

<script>

export default {
    props:['paymentId'],
    components:{},
    data() {
        return {
            loaded: false,
            showPayment: true,
            isActive: false,
        }
    },
    mounted(){
        this.loadData();
        setTimeout(()=>{
             this.isActive = true;
        },150);

        window.addEventListener('message', (event) => {

            if (event.data.startsWith("payment_completed"))
            {
                setTimeout(()=>{
                    this.alert('תשלום בוצע בהצלחה!');
                    this.$router.push('/home');
                    this.close();
                },1500);
                
            }
        });

    },
 
    methods:{    
        close(){
             this.isActive = false;
            setTimeout(()=>{
                this.$emit('close');
            },100);
        },
      
    

        loadData(){  
           this.loaded = true;
        }
    }
}
</script>

<style lang="scss" scoped>
#layout{background: rgba(0, 0, 0, 0.2);position: fixed;inset: 0;z-index: 1;}
.title{font-weight: 800;}
iframe{width: 100%;border: none;outline: none;min-height: 500px;  }

.btn{display: block;margin:  20px auto;font-weight: 800;font-size: 25px;}
.frm{
        &{max-width: 100%;display: flex;gap: 50px;justify-content: center;}
        textarea{resize: none;height: 200px;text-align: right;width: 100%;font-size: 22px;padding: 0 20px;}
        input{text-align: center;width: 100%;font-size: 20px;padding: 0 20px;}
        .btn{width: 100px;display: block;margin: 0 auto;}
    }
    #popup{
            h1{text-align: center;}
        @media (max-width:600px) {overflow-y: scroll; height: 500px;}
         &{ padding: 20px ; ;position: absolute; max-width: 920px; background-color: #fff; margin: 0 auto 50px;left: 50%;top: 50%;height: 600px;overflow: hidden;border-radius: 10px;max-width: 1000px;width: 100%;animation-duration: 0.5s;
        transform: scale(1.15) translate(-50%,-50%);transition: transform 0.1s cubic-bezier(0.465, 0.183, 0.153, 0.946), opacity 0.1s cubic-bezier(0.465, 0.183, 0.153, 0.946); will-change: transform, opacity;opacity:0;overflow:y-scoll;
        &.active{transform: scale(1) translate(-50%,-50%); opacity: 1;transition: transform 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946), opacity 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946)}
    }
}
</style>