<template>
	<div id="settings" class="centered" v-if="loaded">
		<!-- <div class="small_title" v-html="get_word('title_for_settings')"></div> -->
		<h1 v-html="get_word('settings')"></h1>
		<form @submit.prevent="save"  style="padding-bottom: 70px">
			<div class="allPanelWrapper">
				<div class="panelWrapper">
					<div class="panel">
						<ul class="frm">
							<div class="grid_inputs">
								<li>
								<label v-text="get_word('mikve_addr')"></label>
								<input type="text" v-model="form.name" />
							</li>
								<li>
									<label v-text="get_word('phone_balanit')"></label>
									<input type="text" v-model="form.phone" />
								</li>
								<li>
									<label v-text="get_word('address')"></label>
									<input type="text" v-model="form.address" />
								</li>
								<!--<li>
									<label v-text="get_word('manager_phone')"></label>
									<input type="text" v-model="form.manager_phone" />
								</li>
								<li>
									<label v-text="get_word('mikve_capacity')"></label>
									<input type="number" v-model="form.reg_rooms" />
								</li>
								<li>
									<label v-text="get_word('mikve_capacity_full')"></label>
									<input type="number" v-model="form.full_rooms" />
								</li> -->
								<li>
									<label v-text="get_word('apt_length')"></label>
									<input type="number" v-model="form.apt_length" />
								</li>
								<li>
								<label v-text="get_word('close_after')"></label>
									<input min="0" type="number" v-model="form.hours_before_order_restriction" />
								</li>
								<!-- <li>
								<label v-text="get_word('city_time')"></label>
								<select class="select_acc" v-model="form.city_time">
									<option	v-for="acc in cities"	:key="acc.id" :value="acc.id">
										{{ acc.name }}
									</option>
								</select>
							</li> -->
							
								<!-- <li>
								<label v-text="get_word('mikve_accessibility')"></label>
								<select class="select_acc" v-model="form.accessibility">
									<option	v-for="acc in accessibility" :key="acc.id" :value="acc.id">
										{{ acc.name }}
									</option>
								</select>
							</li> -->
							</div>
						</ul>
					</div>
				</div>
				<div class="panelWrapper">
					<div class="panel">
						<ul class="frm">
							<!-- <li>
								<label v-text="get_word('open_hours')"></label>
								<textarea v-model="form.hours" />
							</li> -->

							<li>
								<label v-text="get_word('general_mikve_text')"></label>
								<textarea v-model="form.notes" />
							</li>
						</ul>
					</div>
				</div>
				<div class="btn_wrapper">
					<input type="submit" class="btn" :value="get_word('save')" />
				</div>
			</div>
		</form>
	</div>
</template>
<script>
import axios from "axios";

export default {
	components:{ },
	data() {
		return {
			loaded: false,
			pic_loaded: false,
			form: [],
			accessibility: [],
			cities: [],
			days: [
				{ day_num: 0, name: "ראשון" },
				{ day_num: 1, name: "שני" },
				{ day_num: 2, name: "שלישי" },
				{ day_num: 3, name: "רביעי" },
				{ day_num: 4, name: "חמישי" },
				{ day_num: 5, name: "שישי" },
				{ day_num: 6, name: "שבת" },
			],
			selected: null,
			options: [
				{ id: 10, name: "שירה" },
				{ id: 11, name: "רחל" },
			],
		};
	},
	mounted() {
		let mikve_id = 0;
		if (
			localStorage.getItem("mikve_id") &&
			localStorage.getItem("mikve_id") > 0
		) {
			mikve_id = localStorage.getItem("mikve_id");
		}
		if(!this.isManager())
		{
			this.$router.push('/');
		}
		this.api({ action: "settings", data: { mikve_id } }, (data) => {
			this.accessibility = data.data.accessibility;
			this.cities = data.data.cities;
			this.form = data.data.form;
			this.loaded = true;
		});
	},
	methods: {
		chooseFiles() {
			document.getElementById("fileUpload").click();
		},
		uploadFile(e) {
			let formData = new FormData();

			var headers = {
				"Content-Type": "multipart/form-data",
				Accept: "application/json",
			};

			this.pic_loaded = true;
			formData.append("file", e.target.files[0]);
			axios
				.post(window.api_url + "upload_pic", formData, {
					/*emulateJSON:true,*/ headers: headers,
				})
				.then(
					(response) => {
						if (response.data == "error") {
							this.alert("קרתה שגיאה נא לנסות שנית");
						}
						this.form.pic = response.data.data.id;
						this.form.pic_url = response.data.data.url;
						this.pic_loaded = false;
					},
					(response) => {
						console.error(response.body);
					}
				);
		},
		save() {
			let mikve_id = 0;
			if (
				localStorage.getItem("mikve_id") &&
				localStorage.getItem("mikve_id") > 0
			) {
				mikve_id = localStorage.getItem("mikve_id");
			}

			this.api(
				{
					action: "settings_save",
					data: { form: this.form, mikve_id /*,, balan_days*/ },
				},
				() => {
					this.alert("הפרטים נשמרו בהצלחה");
				}
			);
		},
	},
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style lang="scss" scoped>
#settings{
	.btn_wrapper{position: fixed;background: #fff;bottom: 0px;left: 0px;right: 0px;text-align: center;padding: 15px;z-index: 999;display: flex; gap: 15px;flex-direction: row-reverse;box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
		input{font-size: 24px;font-weight: 600;}
	}
	&{padding-top: 50px;}
	.select_acc{height: 40px;padding: 10px;border: none;background: #eee;border-radius: 10px;font-size: 20px;width: 100%;}
	.pic{background: #ccc;height: 150px;background-position: center;background-size: cover;width: 150px;display: flex;justify-content: center;align-items: center;font-size: 30px;border-radius: 10px;position: relative;}
    .frm{
		li{margin-bottom: 0;}
        // &{max-width: 500px;}
        textarea{resize: none;height: 200px;text-align: right;width: 100%;font-size: 18px;padding: 0 20px;outline: none;    background: #eee;border-radius: 10px;font-size: 20px;border: none;}
        input{text-align: center;width: 100%;font-size: 20px;padding: 0 20px;}
        .btn{width: 100px;display: block;margin: 0 auto;}
    }
   .name {font-size: 20px;}
}
.pic_wrapper{display: flex;align-items: center; gap: 20px;padding-top: 10px;}
.panelWrapper{    display: grid; grid-template-rows: 1fr auto; break-inside: avoid; gap: 30px;
}
.panel{background-color: #fff; position: relative; border-width: 0; box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1); border: 1px solid transparent; border-radius: 4px; padding: 0 25px;}
.allPanelWrapper{
	&{display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    grid-auto-rows: 1fr;
    padding: 10px 0;}
	@media (max-width:600px) { grid-template-columns: repeat(1, 1fr);}
}
.grid_inputs{    display: grid;margin-top: 20px;;grid-template-columns: repeat(2, 1fr);gap: 10px;grid-auto-rows: 1fr;    padding: 10px 0;
	@media (max-width:600px) { grid-template-columns: repeat(1, 1fr);}
}
</style>
