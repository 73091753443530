<template>
	<div class="home centered" v-if="loaded">

		<Popup v-if="showDelete" v-model="showDelete">
			<template #title>האם את בטוח/ה שברצונך למחוק את התור?</template>
			<template #actions>
				<div class="btns">
					<button class="btn " @click="showDelete = false">ביטול</button>
					<button class="btn red" @click="delete_order();showDelete = false">מחיקה</button>
				</div>
			</template>
		</Popup>
		<div class="small_title" v-html="get_word('title_for_balan_sch')"></div>
		<h1>
			{{ get_word("appointments") }}
		</h1>
		<h3>{{ get_word("to_day") }}</h3>
		<div class="date_wrapper">
			<button
				class="btn"
				@click="moveDay"
				v-if="!is_mobile()"
				v-text="get_word('prev_day_appointments')"
			></button>
			<button
				class="btn"
				@click="moveDay"
				v-else
				v-text="get_word('prev_day_appointments_mobile')"
			></button>
			<input
				type="date"
				class="date_input"
				v-model="date"
				@change="updateDate"
			/>
			<button
				class="btn"
				@click="moveDay('1')"
				v-if="!is_mobile()"
				v-text="get_word('next_day_appointments')"
			></button>
			<button
				class="btn"
				@click="moveDay('1')"
				v-else
				v-text="get_word('next_day_appointments_mobile')"
			></button>
		</div>
		<a class="btn addApt" v-html="get_word('add_apt')" @click="addApt()"></a>
		<addApt
			v-if="isAddApt"
			@close="closePopUp()"
			:date="date"
			:mikve_id="mikve_id"
			:prods="prods"
		/>
		<payForOrder v-if="isPayApt" @close="closePopUp()" :paymentId="paymentId" />
		<orderPayed v-if="orderPayed" @close="closePopUp()" :order_id="order_id" />
		<div class="tbl_wrap" v-if="list.length">
			<table class="tbl">
				<thead>
					<tr>
						<th v-html="get_word('hour')"></th>
						<th v-html="get_word('name')"></th>
						<th v-html="get_word('phone')"></th>
						<th v-html="get_word('order_email')"></th>
						<!-- <th v-html="get_word('deep_type')"></th> -->
						<!-- <th v-html="get_word('apt_length')"></th> -->

						<!-- <th v-html="get_word('payment_type')"></th> -->
						<!-- <th v-html="get_word('is_paid')"></th> -->
						<!-- <th v-html="get_word('order_info')"></th> -->
						<!-- <th v-html="get_word('ttl_price')"></th> -->
						<th v-html="get_word('delete_order')"></th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="item in list" :key="item.id" :class="item.arrived == '1' && item.is_paid === '1'? 'active': item.is_paid == '0'? 'not_active': ''">
						<td>{{ item.parse_hour }}</td>
						<td class="name">{{ item.user_data.display_name }}</td>
						<td>{{ item.user_data.phone }}</td>
						<td>{{ item.user_data.username }}</td>
						<!-- <td>{{ item.type_parse }}</td> -->

						<!-- <td>{{ item.apt_length }}</td> -->

						<!-- <td>{{ item.is_cash === "1" ? "תשלום במקום" : "אשראי/ביט" }}</td> -->
						<!-- <td>
							<i v-show="item.is_paid === '1'" class="fas fa-check"></i>
							<i v-show="item.is_paid != '1'" class="fas fa-times"></i>
						</td> -->
					
						
						
						<td style="display:flex;justify-content: center;">
							<button
									class="btn delete_btn"
									title="מחיקת תור"
									@click="showDelete = true;delete_order_id = item.id"
								> <i class="fas fa-trash-alt"></i> </button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div v-else>אין תורים ליום זה</div>
	</div>
</template>

<script>
import addApt from "@/views/addApt.vue";
import payForOrder from "@/views/payForOrder.vue";
import orderPayed from "@/views/orderPayed.vue";
import Popup from "@/views/popup.vue"

export default {
	components: { addApt, payForOrder, orderPayed , Popup },
	data() {
		return {
			loaded: false,
			isPayApt: false,
			showDialog: false,
			showDelete: false,
			isAddApt: false,
			orderPayed: false,
			paymentId: 0,
			order_id: 0,
			popup_order_id: 0,
			delete_order_id: 0,
			note: '',
			list: [],
			prods: [],
			date: this.formatDay(new Date()),
			mikve_id: localStorage.getItem("mikve_id"),
		};
	},
	methods: {
		save_payed() {
			this.api(
				{ action: "order_payed", data: {note:this.note, order_id: this.popup_order_id, type: "cash" } },
				() => {
					this.alert("הפרטים נשמרו בהצלחה");
					this.popup_order_id = 0;
					this.note = "";
					this.updateDate();
				}
			);
		},
		delete_order() {
			this.api(
				{ action: "delete_order", data: { order_id: this.delete_order_id} },
				() => {
					this.alert("התור נמחק בהצלחה");
					this.delete_order_id = 0;
					this.updateDate();
				}
			);
		},
		save_payed_shop(order_id) {
			this.api(
				{ action: "shop_order_payed", data: { order_id: order_id, type: "cash" } },
				() => {
					this.alert("הפרטים נשמרו בהצלחה");
					this.updateDate();
				}
			);
		},
		closePopUp() {
			this.isAddApt = false;
			this.isPayApt = false;
			this.orderPayed = false;
			this.updateDate();
		},
		order_payed(id) {
			this.order_id = id;
			this.orderPayed = true;
		},
		pay_for_order(order_id) {
			this.api(
				{ action: "pay_for_order", data: { order_id: order_id } },
				(data) => {
					if (+data.data > 1) {
						this.paymentId = +data.data;
						this.isPayApt = true;
					}
				}
			);
		},
		updateDate() {
			this.loaded = false;
			let mikve_id = 0;
			if (
				localStorage.getItem("mikve_id") &&
				localStorage.getItem("mikve_id") > 0
			) {
				mikve_id = localStorage.getItem("mikve_id");
			}
			this.api(
				{ action: "home", data: { mikve_id, date: this.date } },
				(data) => {
					this.list = data.data.orders;
					if(data.data.prods && data.data.prods.length > 0)
					{
						this.prods = data.data.prods;
					}
					
					this.loaded = true;
				}
			);
		},
		moveDay(val) {
			const now = new Date(this.date);
			const move = new Date(now);
			if (val === "1") move.setDate(move.getDate() + 1);
			else move.setDate(move.getDate() - 1);
			this.date = this.formatDay(move);
			this.updateDate();
		},
		changeArrival(id, item) {
			const arrival = { id: id, arrived: item };
			this.api({ action: "orders_save", data: { order: arrival } }, () => {
				// this.alert("הפרטים נשמרו בהצלחה");
			});
		},
		formatDay(date) {
			return date.toJSON().slice(0, 10);
		},
		addApt() {
			this.isAddApt = true;
		},
	},
	mounted() {
		this.updateDate();
	},
};
</script>

<style lang="scss" scoped>
.delete_btn{background: #f02e18 !important;padding: 5px 7px!important;font-size: 16px;display: flex;align-items: center;
	.svg-inline--fa{font-size: 17px;color: #fff;}
	@media (max-width:600px) { font-size: 13px; padding: 5px 10px; }
}
.btns{display: flex;justify-content: center;gap: 20px;
	.red{background: red;}
}
#textarea{  width: 50%;color: #000; padding: 10px; margin: 0 auto; display: block; resize: none;}
.ord_wrapper{
	&{display: flex;justify-content: space-between;flex-direction: column; gap: 0px;border: 1px solid #d9d9d9;padding: 5px;width: fit-content;
    gap: 10px;
    border-radius: 10px; align-items: center;}
	@media (min-width:1500px) { flex-direction: row;   }
		.is_paid{
			&{font-size:16px}
			.red{font-weight: 700;color: red;}
			.green{font-weight: 700;color:#58c343;}
		}
	}
.addApt{margin: 20px 0;font-size: 25px;font-weight: 700;padding: 10px 30px;height: auto;}
.home {display: flex; flex-direction: column;justify-content: center; align-items: center;padding-top: 20px;}
.btn_wrapper{
	&{display: flex;gap: 5px;align-items: center;justify-content: center;}
	.btn {line-height: 1;width: fit-content;padding: 0 5px;
		@media (max-width:600px) { font-size: 13px; padding: 5px 10px;height: auto; }
	}
	}
  .date_wrapper {
	&{display: flex;justify-content: center; align-items: center;gap: 10px;padding-bottom: 15px;}
	@media (max-width: 600px) {flex-direction: column;}
	.date_input {font-size: 20px;margin: 0 10px;padding: 0 5px;}
  }
  .tbl_wrap {
	&{width: 100%;}
  @media (max-width:600px) {overflow-x:scroll ;max-width: 100%;margin-bottom: 20px;}}
  .tbl {
	&{border-collapse:separate; border-spacing: 0 7px;width: 100%;}
	th {font-size: 15px; text-align: center; padding: 0 4px}
	tbody {
		tr { 
			&{background: #e9e9e9; border-radius: 10px; box-shadow: 8px 0px 0 #e9e9e9;}
			&.active{  box-shadow: 8px 0px 0 #58c343;}
			&.not_active{    box-shadow: 8px 0px 0 red;}
			td {padding: 5px 10px ;}
            td:last-child { border-top-left-radius: 10px; border-bottom-left-radius: 10px;  }
            td:first-child { border-top-right-radius: 10px; border-bottom-right-radius: 10px; }
        }
		td {padding: 0 10px;text-align: center;}
		.name {width: 15%;}
	}
	.arrival {display: flex;justify-content: center;padding: 20px}
}
h1 {padding-bottom: 10px;}
.checkbox{
	.container_check {display: block; position: relative; padding-left: 35px;margin-bottom: 12px;cursor: pointer;font-size: 22px;
  -webkit-user-select: none;-moz-user-select: none;-ms-user-select: none; user-select: none;}
	.container_check input {position: absolute;opacity: 0;cursor: pointer;height: 0;width: 0;}
	.checkmark { position: absolute; top: 0; left: 0;height: 22px; width: 22px; background-color: #fff;border: 2px solid #354e68;border-radius: 5px}
	.container_check:hover input ~ .checkmark { background-color: #ccc;}
	.container_check input:checked ~ .checkmark {background-color: #354e68;}
	.checkmark:after {content: "";position: absolute; display: none;}
	.container_check input:checked ~ .checkmark:after {display: block;}
	.container_check input { display: block;border:2px solid #000}
	.container_check .checkmark:after {    left: 6px;top: 2px;width: 3px;height: 8px;; border: solid white;border-width: 0 3px 3px 0;-webkit-transform: rotate(45deg); transform: rotate(45deg);}
}
</style>