import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import globalMixin from './globalMixin'
import $bus from './eventbus';

import './css/general.scss'

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

//import Banner from './components/banner.vue'

window.api_url = 'https://api.datjlm.newdev.wizzo.co.il/api_balan/'; 

const app = createApp({
    extends: App
}).use(store).use(router);

app.config.globalProperties.$bus = $bus;

app.mixin(globalMixin);

import axios from 'axios'
import VueAxios from 'vue-axios'
app.use(VueAxios, axios)
/*
import VueLazyLoad from 'vue3-lazyload'
app.use(VueLazyLoad, {
    // options...
  })
  */
  import VueSweetalert2 from 'vue-sweetalert2';
  import 'sweetalert2/dist/sweetalert2.min.css';
  
  app.use(VueSweetalert2);
  
app.mount('#app');
