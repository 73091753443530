<template>
  <div class="chat_wrapper_div" >
    <Chat v-if="loaded"
      :participants="participants"
      :titleImageUrl="titleImageUrl"
      :onMessageWasSent="onMessageWasSent"
      :messageList="messageList"
      :newMessagesCount="newMessagesCount"
      :isOpen="isChatOpen"
      :close="closeChat"
      :open="openChat"
      :showEmoji="true"
      :showFile="false"
      :showEdition="true"
      :showDeletion="true"
      :hideUserInput="hideUserInput"
      :showTypingIndicator="showTypingIndicator"
      :showLauncher="false"
      :showCloseButton="true"
      :colors="colors"
      :alwaysScrollToBottom="alwaysScrollToBottom"
      :messageStyling="messageStyling"
      :placeholder="get_word('write_message')"
      @onType="handleOnType"
      @edit="editMessage">
      <!-- <template v-slot:header><UserIcon size="sml" :user_data="user_data" /> {{participants.map(m=>m.name).join(' & ')}}</template> -->
</Chat>

    <div v-if="!loaded" class="loading_msgs">טוען הודעות..</div>
  </div>
</template>


<script>

import Chat from './vue-beautifull-chat/Launcher.vue'; //vue-beautiful-chat/index.js';

//import UserIcon from './user_icon.vue';
 
export default {
    props: ["user_id", "user_data_prop"],
  components: { Chat },
  data() {
    return {
        user_data: false,
        loaded: false,
        hideUserInput: false,
      messageList: [
          /*
          { type: 'text', author: `me`, data: { text: `Say yes!` } },
          { type: 'text', author: `user1`, data: { text: `No.` } }
          */
      ], // the list of the messages to show, can be paginated and adjusted dynamically
      newMessagesCount: 0,
      isChatOpen: true, // to determine whether the chat window should be open or closed
      showTypingIndicator: '', // when set to a value matching the participant.id it shows the typing indicator for the specific user
      colors: {
        header: {
          bg: '#0a1130',
          text: '#ffffff'
        },
        launcher: {
          bg: '#1e2732'
        },
        messageList: {
          bg: '#ffffff'
        },
        sentMessage: {
          bg: '#4e8cff',
          text: '#ffffff'
        },
        receivedMessage: {
          bg: '#eaeaea',
          text: '#222222'
        },
        userInput: {
          bg: '#f4f7f9',
          text: '#000'
        }
      }, // specifies the color scheme for the component
      alwaysScrollToBottom: true, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
      messageStyling: true // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown)
    }
  },
  computed:{
       participants(){
           if (this.user_data == false) return [];
           return [{
                id: this.user_id,
                name: this.user_data.display_name,
                imageUrl: this.user_data.pic_parsed
                }];
        }, // the list of all the participant of the conversation. `name` is the user name, `id` is used to establish the author of a message, `imageUrl` is supposed to be the user avatar.
        titleImageUrl(){ 
            if (this.user_data != false) return this.user_data.pic_parsed; 
            return '';
        }
  },
  methods: {
    /*
    sendMessage (text) { 
      if (text.length > 0)
      {
        this.newMessagesCount = this.isChatOpen ? this.newMessagesCount : this.newMessagesCount + 1
        this.onMessageWasSent({ author: 'support', type: 'text', data: { text } })
      }
    },
    */
    onMsgInner(data)
    {
      if (data.from == this.user_data.id || data.to == this.user_data.id)
      {
        this.pushMessage({
          author: data.from,
          readed:false, 
          date: '', 
          type: 'text', 
          data: { text: data.text },
          via: ''
        });
      }
    },

    onMessageWasSent (message) { 
      // called when the user sends a message
        let msg = (message.type == 'emoji' ? message.data.emoji : message.data.text);
        // categories 
        this.api({ action: 'chat/send', data: { chat_code: this.user_data_prop.chat_code , msg: msg } }, (data) => { 
          switch(data.data)
          {
            case 'max_per_day': { this.$eventBus.$emit('subscription-panel'); break; }
            case 'restricted': { this.alert(this.get_word('chat_restricted'));  break; }
            case 'restricted_me': { this.alert(this.get_word('chat_restricted_me'));  break; }
            default: { 
              this.pushMessage(message); 
              this.$emit('message_sent', this.user_id, msg);
              break; 
            }
          }
            
        } );
    },
    pushMessage(message)
    {
      //console.log(this.user_data);
     // console.log(message.author);
      message.author = (this.user_data.id != message.author ? 'me': message.author);
      // console.log(this.user_data.id)
      // console.log(message)
      this.messageList = [ ...this.messageList, message ];

    },
    openChat () {
      // called when the user clicks on the fab button to open the chat
      this.isChatOpen = true
      this.newMessagesCount = 0
    },
    closeChat () {
      // called when the user clicks on the botton to close the chat
      this.isChatOpen = false;
      this.$emit('close');
    },
    handleScrollToTop () {
      // called when the user scrolls message list to top
      // leverage pagination for loading another page of messages
    },
    handleOnType () {
      // console.log('Emit typing event')
    },
    editMessage(message){
      const m = this.messageList.find(m=>m.id === message.id);
      m.isEdited = true;
      m.data.text = message.data.text;
    },
    load_data(){
      this.messageList = [];
       // categories 
       let mikve_id = 0;
			if(localStorage.getItem("mikve_id") && localStorage.getItem("mikve_id") > 0)
			{
				mikve_id = localStorage.getItem("mikve_id");
			}
      this.api({ action: 'chat/getMessages_init', data: { chat_code: this.user_data_prop.chat_code ,mikve_id} }, (data) => {

      if (this.isNull(data.data)) { this.loaded = true; return; }
          
        if(data.data.unreaded > 0 )
        {
          this.$emit('chat_change');
        } 
        for (let i in data.data.msgs)
        {
            let msg = data.data.msgs[i];
            
            this.pushMessage({ author: msg.uid_from, readed:msg.readed, date: msg.date, type: 'text', data: { text: msg.msg }, via: msg.via });
        }

        this.loaded = true;

      }, () => {
        this.alert('הטעינה נכשלה. אנא נסה שנית');
      } );
    }
  },
  mounted(){

      if (!this.isNull(this.user_data_prop)) this.user_data = this.user_data_prop;
      else
      {
        this.api({ action: 'search/get', data: {id: this.user_id} }, (data) => { 
            this.user_data = data.data;
        });
      }

      this.hideUserInput = (this.user_data.active === false);

      this.load_data();

      setInterval(()=>{
        this.load_data();
      },30000);
      
  },
  beforeUnmount(){
      // this.$eventBus.$off('new-msg-inner', this.onMsgInner);
  }
}

</script>
<style scoped>
.wait_for_accept {text-align: center; padding:25px; columns: #000; font-size: 18px; background: #f1f1f3; overflow-y: auto;}
.loading_msgs { display: flex; justify-content: center; align-items: center; font-weight: bold; padding: 25px 15px; }
</style>